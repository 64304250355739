<template>
  <div class="modal-container">
    <k-base-modal
      v-for="modal in openedModals"
      :key="modal.name + '_' + modal.idx"
      :modalName="modal.name"
      :idx="modal.idx"
      :dialog-class="modal.dialogClass"
    >
      <template v-slot:default="{ events }">
        <component
          :is="modal.name"
          :modal="modal"
          @close="events.Close"
          @set-loading="events.SetLoading"
        ></component>
      </template>
    </k-base-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ModalStoreTypes } from '../../../store/modules/modal';

export default {
  name: 'modal-container',
  data() {
    return {};
  },
  mounted() {},
  created() {},
  methods: {},
  computed: {
    ...mapGetters({}),
    openedModals() {
      let modals = this.$store.getters[ModalStoreTypes.getters.getModals];

      return [...modals];
    },
  },
  watch: {},
  components: {},
};
</script>
